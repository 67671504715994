<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/SceneEditor3D/Home"
                      homeTitle="3D场景编辑器"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script> 
export default {
  name: 'SceneEditor3D',
  data: () => ({
    currentMenu: "/SceneEditor3D/Home",
    links: [
      {
        title: "首页",
        router: "/SceneEditor3D/Home",
        children: []
      },
      {
        title: "解决方案",
        router: "/SceneEditor3D/solution",
        children: []
      },
      // {
      //   title: "案例",
      //   router: "/SceneEditor3D/case",
      //   children: []
      // }
    ],
  }),
  watch: {
    $route(to) {
      this.currentMenu = to.path;
    }
  },
  mounted() {
    this.currentMenu = this.$route.path;
  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content){
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content && content.router){
        if(content.isNewWindow){
          window.open(window.location.protocol + "//" + window.location.host + content.router)
        }else {
          this.$router.push(content.router)
        }
      }
      this.showMenuChildren = false;
    },
    handleFooterClick: function (router, isNewWindow){
      if(router){
        if(isNewWindow){
          window.open(window.location.protocol + "//" + window.location.host + router)
        }else {
          this.$router.push(router)
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>